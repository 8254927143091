$color-default: #383838;
$color-light: #fff;
$color-pink: #ff527f;
$color-pink-dark: #ea1b66;
$color-green: #7ebca7;
$color-green-dark: #38a37c;
$color-beige: #f4efed;

$font-cormorant: 'Cormorant', serif;
$font-yumincho: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$font-kozuka: '小塚ゴシック Pro','Kozuka Gothic Pro',sans-serif;
$font-hiragino-kakugo: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;