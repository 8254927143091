@charset "UTF-8";

@import 'includes/_variables';
@import 'includes/_fontsize';
@import 'includes/_mediaqueries';

*{
	font-family: "游ゴシック", "YuGothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
body{
	color: $color-default;
}
p,h1,h2,h3,h4,h5,h6,li,dt,dd,th,td{ line-height: 1.6; }
.clearfix:after{
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.center-block{
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.text-center{
	text-align: center;
}
.flex{
	display:-webkit-box;
  	display:-ms-flexbox;
	  display:flex;
}
.f-wrap{
	-ms-flex-wrap: wrap;
  	flex-wrap: wrap;
}
.f-jc-sb{
	-webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.f-jc-c{
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.f-jc-fe{
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
.f-ai-c{
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.f-ai-fs{
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.f-ai-fe{
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}
.f-ac-c{
	-ms-flex-line-pack: center;
	align-content: center;
}
.base{
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 15px;
}

@media only screen and (min-width: 768px) {
	.sp{
		display:none !important;
	}
}

@media only screen and (max-width: 767px) {
	.pc{
		display:none !important;
	}
}

@import 'includes/_layout';