// メディアクエリ

// フォントサイズ
// メディアクエリに変換してくれる

// @include pc-layout() {
//     color: #fff;
// }
// @include sp-layout() {
//     color: #000;
// }

// こんな感じで記述する。
// 案件によってブレイクポイントは変更してね。

// ブレイクポイントが2つの場合
@mixin pc-layout($width: 768px) {
    @media only screen and (min-width: $width) {
        @content;
    }
}
@mixin sp-layout($width: 767px) {
    @media only screen and (max-width: $width) {
        @content;
    }
}

// ブレイクポイントが3つの場合
// @mixin pc-layout($width: 1001px) {
//     @media only screen and (min-width: $width) {
//         @content;
//     }
// }
// @mixin tab-layout($width: 1000px) {
//     @media only screen and (max-width: $width) {
//         @content;
//     }
// }
// @mixin sp-layout($width: 576px) {
//     @media only screen and (max-width: $width) {
//         @content;
//     }
// }
